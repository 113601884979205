import { PageProps } from 'gatsby';
import { InquiryData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Inquiry';
import List from '~/containers/Espace/List';
import inquiry from '~/params/inquiry.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const DemandesPage: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({
  location,
  espace,
  user,
  pageContext: { tab },
  params: { espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <List
        itemList={Item}
        model={
          new InquiryData({
            espaceId,
            params: inquiry,
            wheres: {
              parent: '',
            },
          })
        }
        removeAddButton
        search={location.search}
        tabIndex={tab}
        title={t('demandes.title')}
        user={user}
      />
    </TemplateEspace>
  );
};

export default requireEspace(DemandesPage);
